var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table1",
        attrs: {
          title: "화학물질 변경내역 목록",
          gridHeight: _vm.gridHeight,
          columns: _vm.gridChembase.columns,
          data: _vm.history.chemBases,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }